import React from 'react';
import { Upload, Sparkles, Languages, Copy, Hash, Zap } from 'lucide-react';

interface ExplainerStep {
  icon: React.ElementType;
  title: string;
  description: string;
  color: string;
}

const steps: ExplainerStep[] = [
  {
    icon: Upload,
    title: "Upload Your Image",
    description: "Simply drag & drop or click to upload your image",
    color: "blue"
  },
  {
    icon: Sparkles,
    title: "AI Analysis",
    description: "Our AI analyzes your image for context, objects, and mood",
    color: "purple"
  },
  {
    icon: Languages,
    title: "Choose Your Style",
    description: "Select your preferred language and writing style",
    color: "green"
  },
  {
    icon: Zap,
    title: "Generate Captions",
    description: "Get multiple engaging captions instantly",
    color: "yellow"
  },
  {
    icon: Hash,
    title: "Get Hashtags",
    description: "Receive relevant hashtag suggestions automatically",
    color: "red"
  },
  {
    icon: Copy,
    title: "Copy & Share",
    description: "One-click copy to use on any platform",
    color: "indigo"
  }
];

export function VoiceExplainer() {
  return (
    <section className="py-16 bg-gray-50 dark:bg-gray-800">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            How Caption GPT Works
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Generate perfect captions in seconds with our AI-powered platform
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {steps.map((step, index) => {
            const Icon = step.icon;
            const colors = {
              blue: "bg-blue-100 dark:bg-blue-900 text-blue-500",
              purple: "bg-purple-100 dark:bg-purple-900 text-purple-500",
              green: "bg-green-100 dark:bg-green-900 text-green-500",
              yellow: "bg-yellow-100 dark:bg-yellow-900 text-yellow-500",
              red: "bg-red-100 dark:bg-red-900 text-red-500",
              indigo: "bg-indigo-100 dark:bg-indigo-900 text-indigo-500"
            };

            return (
              <div
                key={index}
                className="relative bg-white dark:bg-gray-900 rounded-xl p-6 shadow-lg hover:shadow-xl transition-all"
              >
                <div className="flex items-start gap-4">
                  <div className={`w-12 h-12 rounded-xl flex items-center justify-center ${colors[step.color]}`}>
                    <Icon className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      {step.title}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300">
                      {step.description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}