import React from 'react';
import { ImageUploadPage } from './components/ImageUploadPage';
import { Features } from './components/Features';
import { ExampleSection } from './components/ExampleSection';
import { PricingSection } from './components/PricingSection';
import { VoiceExplainer } from './components/VoiceExplainer';
import { Testimonials } from './components/Testimonials';
import { CTAButton } from './components/CTAButton';
import { AffiliateBanner } from './components/AffiliateBanner';
import { Image as ImageIcon, Sparkles, Upload } from 'lucide-react';

function App() {
  const [currentPage, setCurrentPage] = React.useState('home');
  const [showAffiliateBanner, setShowAffiliateBanner] = React.useState(true);
  const [startWithDemo, setStartWithDemo] = React.useState(false);

  if (currentPage === 'creator') {
    return (
      <div className="min-h-screen bg-gray-900">
        <div className="max-w-4xl mx-auto py-16 px-4">
          <button
            onClick={() => setCurrentPage('home')}
            className="text-gray-300 hover:text-white mb-8 flex items-center gap-2"
          >
            ← Back to Home
          </button>
          
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-blue-500/20 mb-6">
              <Upload className="w-8 h-8 text-blue-400" />
            </div>
            <h1 className="text-4xl font-bold text-white mb-4">
              Image Upload Coming Soon
            </h1>
            <p className="text-gray-300 text-lg mb-8">
              We're working hard to bring you the ability to upload and generate captions for your own images.
              In the meantime, try our interactive demo!
            </p>
            <CTAButton
              text="Try Demo"
              onClick={() => {
                setStartWithDemo(true);
                setCurrentPage('home');
              }}
              variant="primary"
              size="lg"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      {showAffiliateBanner && (
        <AffiliateBanner onClose={() => setShowAffiliateBanner(false)} />
      )}
      
      <div className="relative bg-gradient-to-b from-gray-800 to-gray-900 py-16 px-4">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 mix-blend-multiply"></div>
          <img
            src="https://images.unsplash.com/photo-1516035069371-29a1b244cc32?auto=format&fit=crop&w=2000&q=80"
            alt="Background"
            className="w-full h-full object-cover opacity-20"
          />
        </div>
        
        <div className="relative max-w-4xl mx-auto text-center">
          <div className="flex items-center justify-center gap-2 mb-6">
            <ImageIcon className="w-10 h-10 text-blue-500" />
            <Sparkles className="w-10 h-10 text-yellow-500" />
          </div>
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Caption GPT
          </h1>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Transform your images into engaging captions with AI magic
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <CTAButton
              text="Try Demo"
              onClick={() => {
                setStartWithDemo(true);
                document.getElementById('examples')?.scrollIntoView({ behavior: 'smooth' });
              }}
              variant="primary"
              size="lg"
            />
            <CTAButton
              text="Start Creating"
              onClick={() => {
                setStartWithDemo(false);
                setCurrentPage('creator');
              }}
              variant="secondary"
              size="lg"
            />
          </div>
        </div>
      </div>

      <VoiceExplainer />
      <Features />
      <div id="examples">
        <ExampleSection />
      </div>
      <Testimonials />
      <div id="pricing">
        <PricingSection />
      </div>

      <footer className="bg-gray-800 py-12 border-t border-gray-700">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold text-white mb-4">Caption GPT</h3>
              <p className="text-gray-300">
                AI-powered captions for your social media content
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white mb-4">Features</h3>
              <ul className="space-y-2 text-gray-300">
                <li>Multi-language Support</li>
                <li>AI Image Analysis</li>
                <li>Custom Tones</li>
                <li>Hashtag Generator</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white mb-4">Company</h3>
              <ul className="space-y-2 text-gray-300">
                <li>About Us</li>
                <li>Blog</li>
                <li>Careers</li>
                <li>Contact</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white mb-4">Legal</h3>
              <ul className="space-y-2 text-gray-300">
                <li>Privacy Policy</li>
                <li>Terms of Service</li>
                <li>Cookie Policy</li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-300">
            <p>© {new Date().getFullYear()} Caption GPT. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;