import React from 'react';
import { MessageSquare } from 'lucide-react';

const tones = [
  { id: 'professional', name: 'Professional' },
  { id: 'creative', name: 'Creative' },
  { id: 'minimal', name: 'Minimal' }
] as const;

interface ToneSelectorProps {
  selected: string;
  onChange: (id: string) => void;
}

export function ToneSelector({ selected, onChange }: ToneSelectorProps) {
  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4 w-full">
      <MessageSquare className="w-5 h-5 text-gray-500" />
      <div className="flex flex-wrap gap-2 w-full sm:w-auto">
        {tones.map((tone) => (
          <button
            key={tone.id}
            onClick={() => onChange(tone.id)}
            className={`px-3 py-1 rounded-full text-sm flex-1 sm:flex-none ${
              selected === tone.id
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
            }`}
          >
            {tone.name}
          </button>
        ))}
      </div>
    </div>
  );
}