import React from 'react';
import { Globe } from 'lucide-react';

const languages = [
  { code: 'en', name: 'English', dir: 'ltr' },
  { code: 'es', name: 'Español', dir: 'ltr' },
  { code: 'fr', name: 'Français', dir: 'ltr' },
  { code: 'ja', name: '日本語', dir: 'ltr' }
] as const;

interface LanguageSelectorProps {
  selected: string;
  onChange: (code: string) => void;
}

export function LanguageSelector({ selected, onChange }: LanguageSelectorProps) {
  const selectedLang = languages.find(lang => lang.code === selected) || languages[0];

  return (
    <div className="flex items-center gap-2 w-full sm:w-auto" dir={selectedLang.dir}>
      <Globe className="w-5 h-5 text-gray-500" />
      <select
        value={selected}
        onChange={(e) => onChange(e.target.value)}
        className="flex-1 sm:flex-none bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg px-3 py-2 text-sm"
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code} dir={lang.dir}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  );
}