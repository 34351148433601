import React, { useState } from 'react';
import { DollarSign, X } from 'lucide-react';

interface AffiliateBannerProps {
  onClose: () => void;
}

export function AffiliateBanner({ onClose }: AffiliateBannerProps) {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      // In production, you would send to your API endpoint
      console.log('Affiliate application:', {
        to: 'affiliates@captiongpt.com',
        ...formData
      });
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (submitted) {
    return (
      <div className="bg-green-500 text-white py-3 relative">
        <div className="max-w-6xl mx-auto px-4 text-center">
          Thank you for your interest! We'll be in touch soon.
          <button
            onClick={onClose}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-2 hover:bg-white/10 rounded-full transition-colors"
            aria-label="Close banner"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-r from-purple-600 to-blue-600 text-white py-3 relative">
      <div className="max-w-6xl mx-auto px-4">
        {!showForm ? (
          <div className="flex items-center justify-center gap-4 flex-wrap">
            <DollarSign className="w-5 h-5" />
            <p className="text-sm md:text-base font-medium">
              Earn 30% lifetime commission on every referral! Join our affiliate program today.
            </p>
            <button
              onClick={() => setShowForm(true)}
              className="bg-white text-purple-600 px-4 py-1 rounded-full text-sm font-semibold hover:bg-opacity-90 transition-opacity"
            >
              Become an Affiliate
            </button>
            <button
              onClick={onClose}
              className="absolute right-2 top-1/2 -translate-y-1/2 p-2 hover:bg-white/10 rounded-full transition-colors"
              aria-label="Close banner"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-wrap items-center justify-center gap-4">
            <input
              type="text"
              placeholder="Your Name"
              required
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="px-4 py-1 rounded-full text-gray-900 text-sm"
            />
            <input
              type="email"
              placeholder="Email Address"
              required
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
              className="px-4 py-1 rounded-full text-gray-900 text-sm"
            />
            <input
              type="tel"
              placeholder="Phone Number"
              required
              value={formData.phone}
              onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
              className="px-4 py-1 rounded-full text-gray-900 text-sm"
            />
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-white text-purple-600 px-4 py-1 rounded-full text-sm font-semibold hover:bg-opacity-90 transition-opacity disabled:opacity-50"
            >
              {isSubmitting ? 'Submitting...' : 'Submit Application'}
            </button>
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="text-sm underline hover:text-white/80"
            >
              Cancel
            </button>
          </form>
        )}
      </div>
    </div>
  );
}