import React from 'react';
import { Zap, Clock, Palette, Share2 } from 'lucide-react';

const features = [
  {
    icon: Zap,
    title: "AI-Powered Creativity",
    description: "Generate engaging captions using advanced AI technology"
  },
  {
    icon: Clock,
    title: "Save Time",
    description: "Get 10 unique captions in seconds, not hours"
  },
  {
    icon: Palette,
    title: "Perfect for Any Content",
    description: "Works with food, portraits, landscapes, and more"
  },
  {
    icon: Share2,
    title: "Easy Sharing",
    description: "Copy and paste captions with a single click"
  }
];

export function Features() {
  return (
    <section className="py-12 bg-gray-50">
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div key={index} className="text-center">
                <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 text-blue-500 mb-4">
                  <Icon className="w-6 h-6" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}