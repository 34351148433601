import React from 'react';
import { Quote } from 'lucide-react';

interface Testimonial {
  name: string;
  role: string;
  content: string;
  platform: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Jessica Park",
    role: "Food & Lifestyle Creator",
    content: "Caption GPT has transformed my content creation workflow. I used to spend hours crafting captions, now I get engaging descriptions in seconds. My engagement has increased by 45% since using this tool!",
    platform: "TikTok"
  },
  {
    name: "David Martinez",
    role: "Adventure Photographer",
    content: "The multi-language support is a game-changer. I can now connect with my global audience in their native languages. The AI understands the context perfectly.",
    platform: "TikTok"
  },
  {
    name: "Sophia Chen",
    role: "Social Media Manager",
    content: "Managing multiple client accounts is now effortless. Caption GPT has cut our content creation time in half while maintaining unique voices for each brand.",
    platform: "TikTok"
  }
];

export function Testimonials() {
  return (
    <section className="py-16 bg-gray-50 dark:bg-gray-800">
      <div className="max-w-3xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Loved by Content Creators
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Join thousands of satisfied creators who've transformed their content game
          </p>
        </div>

        <div className="bg-white dark:bg-gray-900 rounded-xl p-8 shadow-lg">
          <div className="space-y-8">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`${
                  index !== testimonials.length - 1 ? 'border-b border-gray-100 dark:border-gray-800 pb-8' : ''
                }`}
              >
                <div className="flex items-start gap-3">
                  <Quote className="w-8 h-8 text-blue-500 opacity-20 flex-shrink-0" />
                  <div>
                    <p className="text-gray-600 dark:text-gray-300 mb-4 italic">
                      "{testimonial.content}"
                    </p>
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-semibold text-gray-900 dark:text-white">
                          {testimonial.name}
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          {testimonial.role}
                        </p>
                      </div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        via {testimonial.platform}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}