import React from 'react';
import { Check, Zap } from 'lucide-react';

const plans = [
  {
    name: 'Trial',
    price: 'Free',
    description: 'Try before you subscribe',
    features: [
      '3 AI-generated captions',
      'Basic image analysis',
      'Copy to clipboard',
      'No credit card required'
    ],
    buttonText: 'Start Free Trial',
    highlighted: false
  },
  {
    name: 'Monthly',
    price: '$9.99',
    period: '/month',
    description: 'Perfect for regular content creators',
    features: [
      'Unlimited captions',
      'Advanced AI analysis',
      'Priority generation',
      'Save caption history',
      'Premium support'
    ],
    buttonText: 'Subscribe Monthly',
    highlighted: true
  },
  {
    name: 'Annual',
    price: '$99',
    period: '/year',
    description: 'Best value for professionals',
    features: [
      'Everything in Monthly',
      '2 months free ($20 savings)',
      'Early access to new features',
      'Auto-enrollment in affiliate program',
      'Earn 30% lifetime commissions'
    ],
    buttonText: 'Subscribe Yearly',
    highlighted: false
  }
];

export function PricingSection() {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Choose Your Plan</h2>
          <p className="text-lg text-gray-600">
            Start with a free trial or go premium for unlimited captions
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`relative rounded-2xl overflow-hidden ${
                plan.highlighted
                  ? 'bg-blue-50 border-2 border-blue-500 shadow-xl'
                  : 'bg-white border border-gray-200 shadow-lg'
              }`}
            >
              {plan.highlighted && (
                <div className="absolute top-0 right-0 bg-blue-500 text-white px-4 py-1 rounded-bl-lg text-sm font-medium">
                  Most Popular
                </div>
              )}
              <div className="p-8">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{plan.name}</h3>
                <div className="flex items-baseline mb-4">
                  <span className="text-4xl font-bold text-gray-900">{plan.price}</span>
                  {plan.period && (
                    <span className="text-gray-600 ml-1">{plan.period}</span>
                  )}
                </div>
                <p className="text-gray-600 mb-6">{plan.description}</p>
                <button
                  className={`w-full py-3 px-4 rounded-lg font-medium transition-colors ${
                    plan.highlighted
                      ? 'bg-blue-500 text-white hover:bg-blue-600'
                      : 'bg-gray-900 text-white hover:bg-gray-800'
                  }`}
                >
                  {plan.buttonText}
                </button>
                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <Check className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}