import React, { useState } from 'react';
import { Sparkles, Copy, Globe, Wand2 } from 'lucide-react';
import { TRANSLATIONS } from '../data/translations';
import { ToneSelector } from './ToneSelector';
import { LanguageSelector } from './LanguageSelector';

interface Example {
  imageUrl: string;
  category: keyof typeof TRANSLATIONS;
  title: string;
  description: string;
}

const examples: Example[] = [
  {
    imageUrl: "https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?auto=format&fit=crop&w=800&h=1000&q=80&fit=facearea&facepad=3&zoom=1",
    category: 'fashion',
    title: "Personal Brand",
    description: "Perfect for influencers and content creators"
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1539109136881-3be0616acf4b?auto=format&fit=crop&w=800&h=1000&q=80&fit=facearea&facepad=3&zoom=1",
    category: 'fashion',
    title: "Fashion & Style",
    description: "Ideal for fashion brands and models"
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?auto=format&fit=crop&w=800&h=600&q=80",
    category: 'landscape',
    title: "Travel & Landscape",
    description: "Perfect for photographers and adventurers"
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?auto=format&fit=crop&w=800&h=800&q=80",
    category: 'food',
    title: "Food & Lifestyle",
    description: "Ideal for food bloggers and restaurants"
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1541534741688-6078c6bfb5c5?auto=format&fit=crop&w=800&h=1000&q=80&fit=facearea&facepad=3&zoom=1",
    category: 'sports',
    title: "Sports & Fitness",
    description: "Perfect for athletes and fitness enthusiasts"
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1546182990-dffeafbe841d?auto=format&fit=crop&w=800&h=600&q=80",
    category: 'animals',
    title: "Wildlife & Nature",
    description: "Ideal for wildlife photographers and nature lovers"
  }
];

export function ExampleSection() {
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [selectedTone, setSelectedTone] = useState('professional');
  const [activeExample, setActiveExample] = useState<Example>(examples[0]);
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedCaptions, setGeneratedCaptions] = useState<string[]>([]);

  const generateCaptions = () => {
    setIsGenerating(true);
    
    // Get captions from translations based on category, tone, and language
    const translations = TRANSLATIONS[activeExample.category];
    if (translations) {
      const toneTranslations = translations[selectedTone as keyof typeof translations];
      if (toneTranslations) {
        const languageCaptions = toneTranslations[selectedLanguage as keyof typeof toneTranslations];
        if (languageCaptions) {
          setGeneratedCaptions(languageCaptions);
        }
      }
    }
    
    setTimeout(() => {
      setIsGenerating(false);
    }, 800);
  };

  const copyToClipboard = async (text: string, index: number) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <section className="py-16 bg-gradient-to-b from-gray-900 to-gray-800">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">Try Our Demo</h2>
          <p className="text-gray-300 text-lg">Experience the power of AI-generated captions</p>
        </div>

        <div className="grid lg:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {examples.map((example) => (
                <button
                  key={example.title}
                  onClick={() => {
                    setActiveExample(example);
                    setGeneratedCaptions([]);
                    generateCaptions();
                  }}
                  className={`relative rounded-xl overflow-hidden aspect-[4/5] transition-transform hover:scale-105 ${
                    activeExample.title === example.title ? 'ring-2 ring-blue-500' : ''
                  }`}
                >
                  <img
                    src={example.imageUrl}
                    alt={example.title}
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex items-end p-3">
                    <div>
                      <h3 className="text-white text-sm font-medium mb-1">{example.title}</h3>
                      <p className="text-white/80 text-xs">{example.description}</p>
                    </div>
                  </div>
                </button>
              ))}
            </div>

            <div className="bg-gray-800/50 rounded-xl p-6 backdrop-blur-sm">
              <div className="space-y-4">
                <LanguageSelector 
                  selected={selectedLanguage} 
                  onChange={(lang) => {
                    setSelectedLanguage(lang);
                    setGeneratedCaptions([]);
                    generateCaptions();
                  }} 
                />
                <ToneSelector 
                  selected={selectedTone} 
                  onChange={(tone) => {
                    setSelectedTone(tone);
                    setGeneratedCaptions([]);
                    generateCaptions();
                  }} 
                />
              </div>
            </div>
          </div>

          <div className="bg-gray-800/50 rounded-xl p-6 backdrop-blur-sm">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-semibold text-white">Generated Captions</h3>
              <Wand2 className={`w-6 h-6 text-blue-400 ${isGenerating ? 'animate-pulse' : ''}`} />
            </div>
            <div className="space-y-3">
              {generatedCaptions.map((caption, idx) => (
                <div
                  key={idx}
                  className="group relative bg-gray-700/50 p-4 rounded-lg hover:bg-gray-700/70 transition-colors"
                >
                  <p className="text-gray-200 pr-10">{caption}</p>
                  <button
                    onClick={() => copyToClipboard(caption, idx)}
                    className={`absolute right-3 top-1/2 -translate-y-1/2 transition-all p-2 hover:bg-gray-600/50 rounded-full ${
                      copiedIndex === idx ? 'bg-green-500/20 text-green-400' : 'opacity-0 group-hover:opacity-100'
                    }`}
                    title={copiedIndex === idx ? 'Copied!' : 'Copy caption'}
                  >
                    <Copy className="w-4 h-4 text-gray-300" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}