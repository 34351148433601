export const TRANSLATIONS = {
  landscape: {
    professional: {
      en: [
        "Majestic peaks touching the sky | Nature's grandeur 🏔️",
        "Where earth meets heaven | Mountain vista 🗻",
        "Nature's architectural masterpiece | Mountain range ⛰️",
        "Serenity in altitude | Mountain landscape 🏔️",
        "Peak perfection | Nature's majesty 🗻"
      ],
      es: [
        "Picos majestuosos tocando el cielo | Grandeza natural 🏔️",
        "Donde la tierra encuentra el cielo | Vista montañosa 🗻",
        "Obra maestra arquitectónica de la naturaleza ⛰️",
        "Serenidad en la altura | Paisaje montañoso 🏔️",
        "Perfección en las alturas | Majestuosidad natural 🗻"
      ],
      fr: [
        "Pics majestueux touchant le ciel | Grandeur naturelle 🏔️",
        "Là où la terre rencontre le ciel | Vue montagneuse 🗻",
        "Chef-d'œuvre architectural de la nature ⛰️",
        "Sérénité en altitude | Paysage montagneux 🏔️",
        "Perfection des sommets | Majesté naturelle 🗻"
      ],
      ja: [
        "空に触れる雄大な峰々 | 自然の壮大さ 🏔️",
        "大地と天空の出会い | 山々の眺め 🗻",
        "自然が創り出した建築の傑作 ⛰️",
        "高地の静けさ | 山岳風景 🏔️",
        "峰々の完璧さ | 自然の威厳 🗻"
      ]
    },
    creative: {
      en: [
        "Where clouds dance with mountain peaks 🌤️",
        "Nature's cathedral reaching for the stars ✨",
        "Symphony of stone and sky | Mountain dreams 🎵",
        "Whispers of ancient peaks | Nature's story 📖",
        "Mountain magic painted across the horizon 🎨"
      ],
      es: [
        "Donde las nubes bailan con las montañas 🌤️",
        "Catedral natural alcanzando las estrellas ✨",
        "Sinfonía de piedra y cielo | Sueños montañosos 🎵",
        "Susurros de picos antiguos | Historia natural 📖",
        "Magia montañosa pintada en el horizonte 🎨"
      ],
      fr: [
        "Là où les nuages dansent avec les sommets 🌤️",
        "Cathédrale naturelle touchant les étoiles ✨",
        "Symphonie de pierre et de ciel | Rêves montagneux 🎵",
        "Murmures des pics anciens | Histoire naturelle 📖",
        "Magie montagneuse peinte à l'horizon 🎨"
      ],
      ja: [
        "雲が山頂と踊る場所 🌤️",
        "星々に手を伸ばす自然の大聖堂 ✨",
        "石と空の交響曲 | 山の夢 🎵",
        "古き峰々のささやき | 自然の物語 📖",
        "地平線に描かれる山の魔法 🎨"
      ]
    },
    minimal: {
      en: [
        "Mountain views 🏔️",
        "Peak moments ⛰️",
        "Nature's heights 🗻",
        "Mountain peace ✨",
        "Summit serenity 🌄"
      ],
      es: [
        "Vistas montañosas 🏔️",
        "Momentos cumbre ⛰️",
        "Alturas naturales 🗻",
        "Paz montañosa ✨",
        "Serenidad en la cumbre 🌄"
      ],
      fr: [
        "Vues montagneuses 🏔️",
        "Moments sommets ⛰️",
        "Hauteurs naturelles 🗻",
        "Paix montagneuse ✨",
        "Sérénité au sommet 🌄"
      ],
      ja: [
        "山の眺め 🏔️",
        "頂の瞬間 ⛰️",
        "自然の高み 🗻",
        "山の平安 ✨",
        "山頂の静けさ 🌄"
      ]
    }
  },
  food: {
    professional: {
      en: [
        "Fresh ingredients, vibrant flavors | Culinary excellence 🥗",
        "Nutritious elegance on a plate | Healthy dining 🍽️",
        "Garden-fresh goodness | Wholesome meal 🌱",
        "Balanced nutrition meets artistic presentation 🎨",
        "Clean eating redefined | Fresh perspective 🥬"
      ],
      es: [
        "Ingredientes frescos, sabores vibrantes | Excelencia culinaria 🥗",
        "Elegancia nutritiva en el plato | Comida saludable 🍽️",
        "Bondad fresca del jardín | Comida integral 🌱",
        "Nutrición equilibrada con presentación artística 🎨",
        "Alimentación limpia redefinida | Nueva perspectiva 🥬"
      ],
      fr: [
        "Ingrédients frais, saveurs vibrantes | Excellence culinaire 🥗",
        "Élégance nutritive dans l'assiette | Repas sain 🍽️",
        "Fraîcheur du jardin | Repas équilibré 🌱",
        "Nutrition équilibrée et présentation artistique 🎨",
        "Alimentation saine redéfinie | Nouvelle perspective 🥬"
      ],
      ja: [
        "新鮮な食材、鮮やかな味わい | 料理の極み 🥗",
        "栄養価の高い優雅さ | ヘルシーな食事 🍽️",
        "庭園の新鮮な恵み | バランスの取れた食事 🌱",
        "栄養バランスとアート的な盛り付け 🎨",
        "クリーンイーティングの再定義 | 新しい視点 🥬"
      ]
    },
    creative: {
      en: [
        "Dancing flavors in a bowl of sunshine 🌞",
        "Nature's rainbow served with love 🌈",
        "A symphony of colors on your plate 🎨",
        "Where healthy meets happy | Food story 📖",
        "Garden dreams in every bite 🌱"
      ],
      es: [
        "Sabores danzantes en un tazón de sol 🌞",
        "El arcoíris de la naturaleza servido con amor 🌈",
        "Una sinfonía de colores en tu plato 🎨",
        "Donde lo saludable encuentra la felicidad 📖",
        "Sueños del jardín en cada bocado 🌱"
      ],
      fr: [
        "Saveurs dansantes dans un bol de soleil 🌞",
        "Arc-en-ciel naturel servi avec amour 🌈",
        "Symphonie de couleurs dans votre assiette 🎨",
        "Quand santé rime avec bonheur 📖",
        "Rêves du jardin à chaque bouchée 🌱"
      ],
      ja: [
        "太陽の光のボウルで踊る味わい 🌞",
        "愛を込めて提供される自然の虹 🌈",
        "あなたの皿の上の色彩交響曲 🎨",
        "健康と幸せが出会う場所 📖",
        "一口ごとの庭園の夢 🌱"
      ]
    },
    minimal: {
      en: [
        "Fresh vibes 🥗",
        "Healthy bites 🌱",
        "Garden fresh 🥬",
        "Pure food 🍽️",
        "Clean eating ✨"
      ],
      es: [
        "Vibras frescas 🥗",
        "Bocados sanos 🌱",
        "Frescura natural 🥬",
        "Comida pura 🍽️",
        "Comida limpia ✨"
      ],
      fr: [
        "Fraîcheur pure 🥗",
        "Bouchées saines 🌱",
        "Jardin frais 🥬",
        "Cuisine pure 🍽️",
        "Repas clean ✨"
      ],
      ja: [
        "フレッシュ 🥗",
        "ヘルシー 🌱",
        "採れたて 🥬",
        "ピュア 🍽️",
        "クリーン ✨"
      ]
    }
  },
  sports: {
    professional: {
      en: [
        "Peak performance in motion | Athletic excellence 🏃‍♀️",
        "Pushing limits, breaking barriers | Sports journey 🎯",
        "Dedication meets determination | Athletic spirit 💪",
        "Training for greatness | Sports lifestyle 🏃‍♂️",
        "Excellence in motion | Athletic precision ⚡"
      ],
      es: [
        "Rendimiento máximo en movimiento | Excelencia atlética 🏃‍♀️",
        "Superando límites, rompiendo barreras | Viaje deportivo 🎯",
        "Dedicación encuentra determinación | Espíritu atlético 💪",
        "Entrenando para la grandeza | Estilo de vida deportivo 🏃‍♂️",
        "Excelencia en movimiento | Precisión atlética ⚡"
      ],
      fr: [
        "Performance maximale en mouvement | Excellence athlétique 🏃‍♀️",
        "Repousser les limites, briser les barrières | Parcours sportif 🎯",
        "La détermination rencontre le dévouement | Esprit athlétique 💪",
        "S'entraîner pour la grandeur | Mode de vie sportif 🏃‍♂️",
        "Excellence en mouvement | Précision athlétique ⚡"
      ],
      ja: [
        "動きの中の最高のパフォーマンス | アスリートの卓越性 🏃‍♀️",
        "限界を押し上げ、壁を破る | スポーツの旅 🎯",
        "献身と決意の出会い | アスリート精神 💪",
        "偉大さへの訓練 | スポーツライフスタイル 🏃‍♂️",
        "動きの中の卓越性 | アスリートの精密さ ⚡"
      ]
    },
    creative: {
      en: [
        "Dancing with determination on the track 💫",
        "Where sweat turns to stardust | Athletic dreams ✨",
        "Writing stories with every stride 📖",
        "Poetry in motion | Athletic artistry 🎭",
        "Chasing horizons, catching dreams 🌅"
      ],
      es: [
        "Bailando con determinación en la pista 💫",
        "Donde el sudor se convierte en polvo de estrellas ✨",
        "Escribiendo historias con cada zancada 📖",
        "Poesía en movimiento | Arte atlético 🎭",
        "Persiguiendo horizontes, atrapando sueños 🌅"
      ],
      fr: [
        "Danser avec détermination sur la piste 💫",
        "Où la sueur devient poussière d'étoiles ✨",
        "Écrire des histoires à chaque foulée 📖",
        "Poésie en mouvement | Art athlétique 🎭",
        "Poursuivre l'horizon, attraper les rêves 🌅"
      ],
      ja: [
        "トラックの上で決意と共に踊る 💫",
        "汗が星屑に変わる場所 ✨",
        "一歩一歩で物語を紡ぐ 📖",
        "動きの中の詩 | アスリートの芸術性 🎭",
        "地平線を追いかけ、夢をつかむ 🌅"
      ]
    },
    minimal: {
      en: [
        "Run strong 🏃‍♀️",
        "Push limits 💪",
        "Stay focused 🎯",
        "Train hard ⚡",
        "Never quit 💫"
      ],
      es: [
        "Corre fuerte 🏃‍♀️",
        "Supera límites 💪",
        "Mantén el foco 🎯",
        "Entrena duro ⚡",
        "Nunca te rindas 💫"
      ],
      fr: [
        "Cours fort 🏃‍♀️",
        "Dépasse-toi 💪",
        "Reste concentré 🎯",
        "Entraîne dur ⚡",
        "N'abandonne pas 💫"
      ],
      ja: [
        "強く走れ 🏃‍♀️",
        "限界を超えろ 💪",
        "集中を保て 🎯",
        "懸命に練習 ⚡",
        "決して諦めるな 💫"
      ]
    }
  },
  animals: {
    professional: {
      en: [
        "Majestic wildlife in its natural habitat | Nature's grace 🐺",
        "Wild beauty captured | Nature's essence 🌲",
        "Natural elegance in the wilderness | Wildlife moment 🐾",
        "Nature's finest | Wildlife photography 📸",
        "Untamed beauty | Wildlife portrait 🌄"
      ],
      es: [
        "Vida salvaje majestuosa en su hábitat natural 🐺",
        "Belleza salvaje capturada | Esencia natural 🌲",
        "Elegancia natural en la naturaleza | Momento salvaje 🐾",
        "Lo mejor de la naturaleza | Fotografía de vida salvaje 📸",
        "Belleza indómita | Retrato de vida salvaje 🌄"
      ],
      fr: [
        "Faune majestueuse dans son habitat naturel 🐺",
        "Beauté sauvage capturée | Essence naturelle 🌲",
        "Élégance naturelle dans la nature | Moment sauvage 🐾",
        "Le meilleur de la nature | Photographie animalière 📸",
        "Beauté indomptée | Portrait de la faune 🌄"
      ],
      ja: [
        "自然な生息地での威厳ある野生動物 🐺",
        "捉えられた野生の美しさ | 自然の本質 🌲",
        "大自然の中の自然な優雅さ | 野生の瞬間 🐾",
        "自然の最高傑作 | 野生動物写真 📸",
        "野生の美しさ | 野生動物の肖像 🌄"
      ]
    },
    creative: {
      en: [
        "Whispers of the wild | Nature's poetry 🌙",
        "Tales from the wilderness | Wild spirit 🌲",
        "Dancing with shadows | Wildlife stories 🎭",
        "Nature's symphony in motion | Wild dreams 🎵",
        "Where wild hearts roam free | Nature's canvas 🎨"
      ],
      es: [
        "Susurros de lo salvaje | Poesía natural 🌙",
        "Historias del wilderness | Espíritu salvaje 🌲",
        "Bailando con sombras | Historias salvajes 🎭",
        "Sinfonía natural en movimiento | Sueños salvajes 🎵",
        "Donde los corazones salvajes vagan libres 🎨"
      ],
      fr: [
        "Murmures sauvages | Poésie naturelle 🌙",
        "Contes de la nature | Esprit sauvage 🌲",
        "Danser avec les ombres | Histoires sauvages 🎭",
        "Symphonie naturelle en mouvement | Rêves sauvages 🎵",
        "Où les cœurs sauvages errent librement 🎨"
      ],
      ja: [
        "野生のささやき | 自然の詩 🌙",
        "大自然からの物語 | 野生の魂 🌲",
        "影と踊る | 野生の物語 🎭",
        "動きの中の自然の交響曲 | 野生の夢 🎵",
        "野生の心が自由に歩む場所 🎨"
      ]
    },
    minimal: {
      en: [
        "Wild spirit 🐺",
        "Nature's grace 🌲",
        "Wild beauty 🐾",
        "Pure nature 📸",
        "Wild heart 🌄"
      ],
      es: [
        "Espíritu salvaje 🐺",
        "Gracia natural 🌲",
        "Belleza salvaje 🐾",
        "Naturaleza pura 📸",
        "Corazón salvaje 🌄"
      ],
      fr: [
        "Esprit sauvage 🐺",
        "Grâce naturelle 🌲",
        "Beauté sauvage 🐾",
        "Nature pure 📸",
        "Cœur sauvage 🌄"
      ],
      ja: [
        "野生の魂 🐺",
        "自然の優雅さ 🌲",
        "野生の美 🐾",
        "純粋な自然 📸",
        "野生の心 🌄"
      ]
    }
  },
  fashion: {
    professional: {
      en: [
        "Style in motion | Fashion forward 👗",
        "Where elegance meets attitude | Fashion story 💫",
        "Curated fashion moments | Style diary 🌟",
        "Modern elegance defined | Fashion edit ✨",
        "Timeless style speaks | Fashion essence 🎭"
      ],
      es: [
        "Estilo en movimiento | Moda adelante 👗",
        "Donde la elegancia encuentra la actitud 💫",
        "Momentos de moda curados | Diario de estilo 🌟",
        "Elegancia moderna definida | Edición de moda ✨",
        "El estilo atemporal habla | Esencia de la moda 🎭"
      ],
      fr: [
        "Style en mouvement | Mode avant-gardiste 👗",
        "Où l'élégance rencontre l'attitude 💫",
        "Moments de mode sélectionnés | Journal de style 🌟",
        "L'élégance moderne définie | Édition mode ✨",
        "Le style intemporel parle | Essence de la mode 🎭"
      ],
      ja: [
        "動きの中のスタイル | ファッション最前線 👗",
        "エレガンスと姿勢が出会う場所 💫",
        "キュレーションされたファッションの瞬間 🌟",
        "現代のエレガンスを定義する ✨",
        "タイムレスなスタイルが語る | ファッションの本質 🎭"
      ]
    },
    creative: {
      en: [
        "Dancing with fabrics in the urban jungle 🌆",
        "Where colors paint stories on silk canvas 🎨",
        "Poetry in motion, style in bloom 🌸",
        "Weaving dreams into reality | Fashion tales ✨",
        "Urban symphony in threads and patterns 🎭"
      ],
      es: [
        "Bailando con telas en la jungla urbana 🌆",
        "Donde los colores pintan historias en seda 🎨",
        "Poesía en movimiento, estilo en flor 🌸",
        "Tejiendo sueños en realidad | Cuentos de moda ✨",
        "Sinfonía urbana en hilos y patrones 🎭"
      ],
      fr: [
        "Danser avec les tissus dans la jungle urbaine 🌆",
        "Où les couleurs peignent des histoires sur la soie 🎨",
        "Poésie en mouvement, style en fleur 🌸",
        "Tisser les rêves en réalité | Contes de mode ✨",
        "Symphonie urbaine en fils et motifs 🎭"
      ],
      ja: [
        "都会のジャングルで布と踊る 🌆",
        "シルクのキャンバスに物語を描く色彩 🎨",
        "動きの中の詩、咲き誇るスタイル 🌸",
        "夢を現実に織り込む | ファッション物語 ✨",
        "糸とパターンの都会のシンフォニー 🎭"
      ]
    },
    minimal: {
      en: [
        "Style speaks 👗",
        "Urban grace ✨",
        "Fashion flow 🌟",
        "Chic moment 💫",
        "Pure elegance 🎭"
      ],
      es: [
        "El estilo habla 👗",
        "Gracia urbana ✨",
        "Flujo de moda 🌟",
        "Momento chic 💫",
        "Pura elegancia 🎭"
      ],
      fr: [
        "Le style parle 👗",
        "Grâce urbaine ✨",
        "Flux de mode 🌟",
        "Moment chic 💫",
        "Pure élégance 🎭"
      ],
      ja: [
        "スタイルが語る 👗",
        "都会の優雅さ ✨",
        "ファッションの流れ 🌟",
        "シックな瞬間 💫",
        "純粋なエレガンス 🎭"
      ]
    }
  }
} as const;